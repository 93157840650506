<script>
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import LoyaltyCardForm from '/~/drawer/loyalty-cards/components/card-form.v2.vue'
import LoyaltyEditV1 from '/~/drawer/loyalty-cards/loyalty-edit.vue'

export default {
  name: 'loyalty-memberships-edit',
  components: {
    LoyaltyCardForm,
    BaseAsidePage,
  },
  extends: LoyaltyEditV1,
  setup() {
    const router = useRouter()

    function onUpdated() {
      router.back()
    }

    return {
      ...LoyaltyEditV1.setup?.(...arguments),
      onUpdated,
    }
  },
}
</script>

<template>
  <base-aside-page ref="baseAsidePage" :title="pageTitle" no-padding>
    <div class="min-h-full px-5 pb-5">
      <loyalty-card-form
        v-if="isViewReady"
        :card="loyaltyCard"
        :edit="true"
        @updated="onUpdated"
      />

      <div v-else class="space-y-[15px]">
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
        <div class="h-5 animate-pulse rounded-xl bg-strong" />
      </div>
    </div>
  </base-aside-page>
</template>
